import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import Button from "../components/Button";
import PageTitle from "../components/PageTitle";
import DestinationAutoSuggest from "../components/DestinationAutosuggest";

import { device } from "../globalStyles";

import { useFormContext } from "../context/formContext";
import { useLangContext } from "../context/langContext";

import { preventEnterSubmit } from "../constants/utils";
import I18N from "../constants/languages/NL/nl_translations";
import Seo from "../components/SEO";

const StyledLayout = styled(Layout)`
  position: relative;
`;

const Container = styled.div`
  padding: var(--gutter);
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  @media (${device.minTablet}) and (min-height: 890px) {
    padding-top: 80px;
  }

  form {
    width: 100%;
    @media (${device.minTablet}) {
      max-width: min(50vw, 872px);
    }
    & .autosuggest {
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 32px;
        @media (${device.minTablet}) and (min-height: 780px) {
          margin-bottom: 80px;
        }
      }
    }

    button {
      margin: auto;
    }
  }
`;

const DestinationPage = () => {
  const { form, setForm } = useFormContext();
  const { lang } = useLangContext();
  const copy = I18N[lang].destinations;

  const [error, setError] = useState("");
  const [bucketlist_dest1, setBucketlist_dest1] = useState(
    form.bucketlist_dest1
  );
  const [bucketlist_dest2, setBucketlist_dest2] = useState(
    form.bucketlist_dest2
  );
  const [bucketlist_dest3, setBucketlist_dest3] = useState(
    form.bucketlist_dest3
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!bucketlist_dest1 && !bucketlist_dest2 && !bucketlist_dest3) {
      return setError(copy.minimum1);
    }
    const uid = (new URLSearchParams(window.location.search)).get("uid");

    setForm((form) => ({
      ...form,
      bucketlist_dest1,
      bucketlist_dest2,
      bucketlist_dest3,
      uid,
    }));

    navigate("/activities");
  };

  /*
   TODO:
    use onSuggestionSelected to store values in state
    if state empty setError (please select value from dropdown)
   */

  return (
    <StyledLayout title={copy.pageTitle} pageName="Dream Destinations">
      <Container>
        <PageTitle label="1/3" title={copy.title} subtitle={copy.subtitle} />

        <form onSubmit={handleSubmit}>
          <DestinationAutoSuggest
            id="bucketlist_dest1"
            label={`${copy.destination} 1`}
            onChange={setBucketlist_dest1}
            className="autosuggest"
            error={error}
            required
            inputProps={{
              onKeyDown: preventEnterSubmit,
            }}
            cityCode={bucketlist_dest1}
          />
          <DestinationAutoSuggest
            id="bucketlist_dest2"
            label={`${copy.destination} 2`}
            onChange={setBucketlist_dest2}
            className="autosuggest"
            inputProps={{ onKeyDown: preventEnterSubmit }}
            cityCode={bucketlist_dest2}
          />
          <DestinationAutoSuggest
            id="bucketlist_dest3"
            label={`${copy.destination} 3`}
            onChange={setBucketlist_dest3}
            className="autosuggest"
            cityCode={bucketlist_dest3}
          />
          <Button
            type="submit"
            disabled={
              !bucketlist_dest1 && !bucketlist_dest2 && !bucketlist_dest3
            }
          >
            {copy.button}
          </Button>
        </form>
      </Container>
    </StyledLayout>
  );
};

export default DestinationPage;

export const Head = () => <Seo />;
