import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";

import {
  textInputStyles,
  inputLabelStyles,
  device,
  labelStyles,
} from "../globalStyles";

import { isTouchDevice } from "../constants/utils";
import { COUNTRIES_OBJECT } from "../constants/countries";
import {
  DESTINATIONS_EN,
  DESTINATIONS_FR,
  DESTINATIONS_NL,
  DESTINATIONS_OBJECT,
} from "../constants/destinations";

import { useLangContext } from "../context/langContext";

import ErrorIcon from "../svg/error.svg";

const StyledWrapper = styled.div`
  & .react-autosuggest__input {
    ${textInputStyles};
    &:focus-visible {
      outline: none;
      border-color: var(--dark-blue-3);
    }
  }

  & .react-autosuggest__input--open {
    border-bottom: none;
  }

  & .react-autosuggest__suggestion {
    border: solid 1px var(--dark-blue-3);

    &:not(:first-child) {
      border-top: none;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background: var(--light-blue);
  }

  & .react-autosuggest__container--open {
    position: relative;
  }

  & .react-autosuggest__suggestions-container {
    position: absolute;
    background: white;
    width: 100%;
  }

  & .react-autosuggest__suggestions-container--open {
    max-height: 220px;
    overflow-y: auto;
  }
`;

const Label = styled.label`
  ${inputLabelStyles};
`;

const Option = styled.div`
  padding: 0.8rem 1.6rem;
  text-align: left;

  & > p:first-of-type {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: ${16 / 12}em;
    color: var(--grey);
  }

  & > p:last-of-type {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: ${24 / 16}em;
  }

  &:hover {
    background: var(--light-blue);
  }

  @media (min-height: 670px) and (max-width: 1439px),
    (min-height: 700px) and (${device.minLaptopL}) {
    padding: 1.6rem;
  }

  @media (${device.minTablet}) {
    padding: 16px 28px;
  }
`;

const Error = styled.p`
  ${labelStyles};
  color: var(--red);
  margin-top: 4px;
  margin-left: calc(var(--horizontal-padding) + 1px);
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, lang) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  let DESTINATIONS = [];
  if (lang === "nl") DESTINATIONS = DESTINATIONS_NL;
  if (lang === "en") DESTINATIONS = DESTINATIONS_EN;
  if (lang === "fr") DESTINATIONS = DESTINATIONS_FR;

  return inputLength === 0
    ? []
    : DESTINATIONS.filter(
        // (country) => country['nl'].toLowerCase().slice(0, inputLength) === inputValue
        (destination) =>
          destination[lang].toLowerCase().includes(inputValue) ||
          COUNTRIES_OBJECT[destination.country_code][lang]
            .toLowerCase()
            .includes(inputValue)
      );
};

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion, lang) => (
  <Option>
    <p>
      {suggestion.city_code} – {COUNTRIES_OBJECT[suggestion.country_code][lang]}
    </p>
    <p>{suggestion[lang]}</p>
  </Option>
);

const DestinationAutoSuggest = (props) => {
  const { lang } = useLangContext();

  const [value, setValue] = useState(
    DESTINATIONS_OBJECT?.[props.cityCode]?.[lang] || ""
  );
  const [suggestions, setSuggestions] = useState([]);
  const [savedCityCode, setSavedCityCode] = useState(props.cityCode || "");
  const [savedValue, setSavedValue] = useState("");

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setSavedValue((savedValue) => {
      if (newValue !== savedValue) {
        setSavedCityCode("");
      }
      return savedValue;
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, lang));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSavedCityCode((cityCode) => {
      setValue((value) => {
        if (!value) {
          setSavedCityCode("");
          return "";
        }
        if (DESTINATIONS_OBJECT[cityCode || value]) {
          return DESTINATIONS_OBJECT[cityCode || value][lang];
        } else {
          return "";
        }
      });
      return cityCode;
    });

    setSuggestions([]);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    setSavedCityCode(suggestion.city_code);
    setSavedValue(suggestion[lang]);
    return suggestion[lang];
  };

  const handleBlur = (event, { highlightedSuggestion }) => {
    if (!highlightedSuggestion) return;
    getSuggestionValue(highlightedSuggestion);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    value,
    onChange,
    onBlur: handleBlur,
    required: props.required,
    ...props.inputProps,
  };

  useEffect(() => {
    props?.onChange(savedCityCode);
  }, [savedCityCode]);

  // Finally, render it!
  return (
    <StyledWrapper className={props.className}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(s) => renderSuggestion(s, lang)}
        inputProps={inputProps}
        id={props.id}
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion={isTouchDevice()}
      />
      {props.error && (
        <Error>
          <ErrorIcon />
          {props.error}
        </Error>
      )}
    </StyledWrapper>
  );
};

export default DestinationAutoSuggest;
